@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap');
.techstack{
    max-width: 100vw;
    padding: 1em 1em;
    margin: 0em;
    padding-left: 5.5em;
    background-color: rgb(164 201 110 / 20%);
    border-radius: 19%;
}
.techstack hr{
    color: #138781;
    width: 55vw;
    margin: 8px auto;
    border: 2px solid #138781;
}
.techstack h2{
    font-family: 'Tektur', sans-serif;
    font-size: 2.8em;
    color: #23827d;
}

.techstack .card{
    cursor: pointer;
    height: 3.5em;
}
.techstack .card:hover{
    box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
    background-color: #9cc886;
    color: white;
    border: none;
    transition: 1s;
}

.skill_name{
    font-size: 1.5em;
}

@media (max-width:1000px){
    .techstack{
        padding: 1em 1em;
    }
}

/* ----- DARK MODE ----- */
#dark .techstack{
    background-color: #546b4735;
}
#dark .techstack .card{
    background-color: rgba(44, 69, 29, 0.7);
    color: rgb(234, 251, 238);
}
#dark .techstack h2{
    color: rgb(148, 219, 176)
}
#dark .techstack .card:hover{
    box-shadow: rgba(155, 155, 155, 0.55) 0px 5px 15px;
    background-color: #a1c98d;
    color: rgb(31, 31, 31);
    transition: 1s;
}