@import url('https://fonts.googleapis.com/css2?family=Acme&family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital@1&family=Oswald&display=swap');
.project{
    max-width: 100vw;
    padding: 0em 1em;
    margin: 0em 0em;
    padding-left: 5.5em;
    background-color: rgb(74 199 220 / 17%);
    border-radius: 19%;
    padding-top: 1em;
}
.project hr{
    color: #138781;
    width: 55vw;
    margin: 8px auto;
    border: 2px solid #138781;
}
.project h2{
    font-family: 'Acme', sans-serif;
    font-size: 2.8em;
    color: #23827d;
}
.project p{
    font-family: 'Cabin', sans-serif;
    font-size: 1.2em;
}

#ads{
    margin: 30px 0px 30px 0px;
}
#ads .card-notify-badge{
    position: absolute;
    left: -20px;
    top: -20px;
    background: #f29f67;
    text-align: center;
    border-radius: 30px;
    color: black;
    padding: 5px 10px;
    font-size: 1.1em;
    font-weight: bold;
}

.project .card:hover{
    background-color: white;
    border-radius: 5px;
    box-shadow: 12px 15px 15px 0px rgba(46, 61, 73, 0.25);
    transition: all 0.8s ease;
}

.project .card img{
    height: auto;
    width: 100%;
}
.project .card-detail-badge{
    background-color: #138781;
    text-align: center;
    border-radius: 30px;
    color: white;
    padding: 5px 10px;
    font-size: 0.9em;
    font-weight: bold;
}
.project .card-detail-badge:hover{
    background-color: #FFF;
    text-align: center;
    border-radius: 30px;
    color: #138781;
    padding: 5px 10px;
    font-size: 14px;
    transition: 1.2s;
    font-weight: bold;
}
.project .card-image-overly span{
    display: inline-block;
    margin-left: 5px;
}

.ad-title h6{
    font-family: 'Oswald', sans-serif;
}

.ad-btn{
    display: inline-block;
    text-align: center;
    color: white;
    border-radius: 80px;
    font-size: 1em;
    line-height: 35px;
    text-decoration: none;
    margin: 10px;
    overflow: hidden;
    position: relative;
}
.btn-git{
    background-color: black;
    color: white;
    width: 5em;
    border: 1px solid black;
}
.btn-git:hover{
    background-color: white;
    color: black;
    transition: 1s;
    width: 6em;
    border: 1px solid black;
}
.btn-yt{
    background-color: rgb(183, 0, 0);
    color: white;
    width: 6em;
    border: 1px solid rgb(183, 0, 0);
}
.btn-yt:hover{
    background-color: white;
    color: rgb(183, 0, 0);
    transition: 1s;
    width: 7em;
    border: 1px solid rgb(183, 0, 0);
}
.btn-link{
    background-color: #4285F4;
    color: white;
    width: 5em;
    border: 1px solid #4285F4;
}
.btn-link:hover{
    background-color: white;
    color: #4285F4;
    transition: 1s;
    width: 6em;
    border: 1px solid #4285F4;
}

@media (max-width:1000px){
    .project{
        padding: 0em 1em;
        margin: 0em 0em;
    }
}

/* ----- DARK MODE ----- */
#dark .project{
    background-color: rgba(50, 141, 157, 0.17);
}
#dark .project h2{
    color: #33c1ba;
}
#dark .card-notify-badge{
    background-color: #853d0c;
    color: #FFF;
}
#dark .project .card{
    background-color: #16605c;
    color: white;
}
#dark .project .card:hover{
    box-shadow: 12px 15px 15px 0px rgba(137, 182, 216, 0.25);
    transition: all 0.8s ease;
    color: rgb(251, 234, 204);
}
#dark .project .card-detail-badge{
    background-color: #38cec6;
    color: black;
}
#dark .project .card-detail-badge:hover{
    background-color: #16605c;
    color: rgb(255, 255, 255);
}