@import url('https://fonts.googleapis.com/css2?family=Rubik+Lines&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
.about{
    margin: 2em 1em;
    padding: 1em;
    margin-left: 5em;
    border-radius: 4em;
    box-shadow: rgb(109 109 109 / 60%) 20px 0px 80px -30px, rgba(100, 100, 100, 0.6) 0px 20px 80px -30px, rgba(100, 100, 100, 0.6) 20px 0px 80px -30px inset, rgba(100, 100, 100, 0.6) 0px 20px 80px -30px inset;
}

.about-img img{
    margin: 5px; 
    width: 90%;
    border-radius: 20%; 
    display: flex;
}

.about-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0em 0.3em;
}
.about-content h1{
    color: rgb(0, 74, 106);
    font-family: 'Indie Flower', sans-serif;
    font-weight: bolder;
    font-size: 3em;
    border-bottom: 2px solid gray;
    text-align: center;
}
.about-content p{
    font-size: 100%;
    font-style: italic;
    text-align: justify;
}
.about-app{
    max-width: 119em;
    padding: 0px;
}

@media (max-width:1000px){
    .about{
        margin-left: 1em;
    }
    .about-content h1{
        font-size: 2.3em;
    }
    .about-content p{
        font-size: 75%;
    }
    .about-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .about-img img{
        margin: 5px; 
        width: 90%;
        border-radius: 20%; 
        display: flex;
    }
}

@media (max-width:600px){
    .about-img img{
        width: 60%;
    }
    .about-content h1{
        font-size: 2.3em;
    }
    .about-content p{
        font-size: 85%;
    }
}

/* ----- DARK MODE ----- */
#dark .about{
    background-color: #1f1f1f;
    box-shadow: rgba(179, 179, 179, 0.4) 20px 0px 80px -30px, rgba(179, 179, 179, 0.4) 0px 20px 80px -30px, rgba(179, 179, 179, 0.4) 20px 0px 80px -30px inset, rgba(179, 179, 179, 0.4) 0px 20px 80px -30px inset;
}
#dark .about-content h1{
    color: rgb(61, 207, 237);
    border-bottom: 2px solid rgb(35, 130, 218);
}

